:root {
  /* --primary-color: #c73030;
  --secondary-color: #fa3939;
  --third-color: #882525;
  --fourth-color: #727272;
  --fifth-color: #ffffff; */

  /* --primary-color: #853044;
  --secondary-color: #da4f70;
  --third-color: #da4f70;
  --fourth-color: #727272;
  --fifth-color: #ffffff; */

  --primary-color: #008081;
  --secondary-color: #02b1b1;
  --third-color: #02b1b1;
  --fourth-color: #727272;
  --fifth-color: #ffffff;
  --text-color: #6a6a6a;
  --link-color: #000000;
  --link-hover-color: #6a6a6a;
}

body {
  color: var(--text-color);
}

a {
  color: var(--link-color);
}

a:hover {
  color: var(--link-hover-color);
  text-decoration: none;
}

a:active {
  color: var(--secondary-color);
}

#post a {
  color: var(--secondary-color);
}

#post a:hover {
  color: var(--link-hover-color);
  text-decoration: none;
}

#post a:active {
  color: var(--link-hover-color);
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--third-color);
}

::selection {
  background-color: var(--secondary-color);
  color: var(--fifth-color);
}

.primary {
  color: var(--primary-color);
}

.secondary {
  color: var(--secondary-color);
}

.third {
  color: var(--third-color);
}

.fourth {
  color: var(--fourth-color);
}

.link {
  color: var(--link-color);
}

.text {
  color: var(--text-color);
}

.reading-bar {
  background: var(--secondary-color)
}