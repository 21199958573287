/* General */

.app {
  min-height: 100vh;
}

pre {
  padding: 1rem;
  white-space: pre-wrap;
}


/* Top Bar */

.top-bar-container {
  height: 4rem;
  position: fixed;
  top: 0;
  z-index: 1080;
  border-bottom: 1px #fafafa solid;
}

/* Footer */

.footer-container {
  border-top: 1px #fafafa solid;
  height: 4rem;
  padding: 0;
}

/* Menu */

.menu-icon {
  font-size: 1.5rem;
  cursor: pointer;
}

.menu-base {
  position: fixed;
  left: 0;
  top: -4rem;
  z-index: 1100;
  min-width: 100%;
  min-height: 100;
  margin-top: 4rem;
  padding-top: 4rem;
  padding-left: calc((100vw - 45rem) / 2);
  padding-right: calc((100vw - 45rem) / 2);
}

.menu-curtain {
  background-color: black;
  opacity: 0.2;
  height: calc(100vh);
}

.menu-item {
  background-color: #ffffff;
  height: 4rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.3rem;
  border-top: 1px #f0f0f0 solid;
}

/* Riddles */

/* .riddle-code {

} */

.riddle-code input {
  width: 15rem;
  height: 4rem;
  text-align: center;
  font-size: 3.8rem;
}

.riddle-code button {
  width: 15rem;
  height: 4rem;
  border: 1px solid #cccccc;
}

/* Slideshow */

.slideshow-container {
  width: 100%;
  position: relative;
  margin: auto;
}

.slideshow-container img {
  box-shadow: 0 10px 10px #e3e7eb;
  width: 100%;
  transition: display 0.6s ease;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: black;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  text-decoration: none;
}

.next {
  right: 0px;
  border-radius: 0 3px 3px 0;
}

.prev {
  left: 0px;
  border-radius: 3px 0 0 3px;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.dot {
  cursor: pointer;
  height: 0.8rem;
  width: 0.8rem;
  margin: 2rem 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #717171;
}

/* Fading animation */

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

/* Buzzwords */

.buzzword {
  border-radius: 5px;
  margin: 2px;
  padding: 5px;
  font-family: monospace;
  font-size: 0.65rem;
}

/* Utils */

.max-width-containter {
  width: 100%;
  max-width: 45rem;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.06);
}

.fill-height {
  min-height: calc(100vh - 4rem);
  margin-top: 4rem;
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-90-cw {
  transform: rotate(90deg);
}

.reading-bar-container {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.4rem;
  z-index: 1090;
}

.reading-bar {
  width: 100%;
  height: 100%;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left;
  transform-origin: left;
  transition: -webkit-transform 50ms cubic-bezier(0.45, 0.05, 0.55, 0.95) 0ms;
  transition: transform 50ms cubic-bezier(0.45, 0.05, 0.55, 0.95) 0ms;
  transition: transform 50ms cubic-bezier(0.45, 0.05, 0.55, 0.95) 0ms,
    -webkit-transform 50ms cubic-bezier(0.45, 0.05, 0.55, 0.95) 0ms;
}

/* Posts */

.post-img {
  max-width: 100%;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.2);
}

/* About */

.social-link {
  border-radius: 25%;
  font-size: 1.2rem;
  padding: 0.5rem;
  margin: 0.5rem;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.2);
}