body {
  margin: 0;
  font-family: "Libre Franklin", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

/* h1 {
  font-size: 2.4rem;
}
h2 {
  font-size: 2.2rem;
}
h3 {
  font-size: 2rem;
}
h4 {
  font-size: 1.8rem;
}
h5 {
  font-size: 1.6rem;
} */

h1 {
  font-size: 2rem;
  font-weight: 900;
}
h2 {
  font-size: 1.8rem;
  font-weight: 600;
}
h3 {
  font-size: 1.6rem;
}
h4 {
  font-size: 1.4rem;
}
h5 {
  font-size: 1.2rem;
}

/* @media (max-width: 650px) {
  body {
    font-size: 12px;
  }
} */

code {
  font-family: "Ubuntu Mono", monospace;
}

h5 {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}
